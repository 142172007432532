import config from '@/config';
import _ from 'lodash';

import phoneConfig from '@/config/phone';
import { HOST_PORTAL_URL } from '@/services/connections.service';
import { COOKIE_MAP, getCookie } from '@/services/cookie.service';
import * as HttpService from '@/services/http.service';
import { getUserAuth } from '@/services/identity.service';

import {
  CREATE_USER,
  GET_USER,
  GET_USER_COMPLETED_ORDERS,
  GET_USER_ONGOING_EVENTS,
  GET_USER_ONGOING_ORDERS,
  GET_USER_ORDER_DETAILS,
  GET_USER_PAST_EVENTS,
  POST_LEAD_MARKETING_REFERENCE,
  RECENT_OTP,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_TIMEZONE,
  USER_LOGIN
} from '@/services/url.service';

const { hebaWhatsapp } = phoneConfig.phoneNumbers;
export const createUser = (userLoginDetails) =>
  HttpService.postWithoutAuth(CREATE_USER, userLoginDetails);

export const userLogin = (userLoginDetails) =>
  HttpService.postWithoutAuth(USER_LOGIN, userLoginDetails);

export const recentLoginOtp = (userLoginDetails) =>
  HttpService.postWithoutAuth(RECENT_OTP, userLoginDetails);

export const getUser = (userId) => HttpService.getWithoutAuth(GET_USER(userId));

export const getUserOngoingOrders = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_ONGOING_ORDERS(userId, skip, limit));

export const getUserCompletedOrders = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_COMPLETED_ORDERS(userId, skip, limit));

export const getUserOrderDetails = (userId, orderId) =>
  HttpService.getWithAuth(GET_USER_ORDER_DETAILS(userId, orderId));

export const updateUserDetails = (userId, userDetails) =>
  HttpService.postWithAuth(UPDATE_USER_DETAILS(userId), userDetails);

const createLeadMarketingReference = (leadMarketingReferenceDetails) =>
  HttpService.postWithAuth(
    POST_LEAD_MARKETING_REFERENCE,
    leadMarketingReferenceDetails
  );

export const updateUserAddress = (userId, addressDetails) =>
  HttpService.postWithAuth(UPDATE_USER_ADDRESS(userId), addressDetails);

export const updateUserTimeZone = (userId, timeZone) =>
  HttpService.postWithoutAuth(UPDATE_USER_TIMEZONE(userId), timeZone);

export const getUserOngoingEvents = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_ONGOING_EVENTS(userId, skip, limit));

export const getUserPastEvents = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_PAST_EVENTS(userId, skip, limit));

export const getPurchaseEventInfoForGA = async ({
  userId,
  orderId,
  paymentFlowSource
}) => {
  const { status, entity } = await getUserOrderDetails(userId, orderId);
  if (status && entity?.orderItems) {
    return {
      orderItems: entity.orderItems,
      orderId,
      orderTotal: entity.orderTotal,
      paymentFlowSource
    };
  }
  return false;
};

const getGCLIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.GCL });
  return cookieValue?.split('.')?.[2] || null;
};

const getMSCLKIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.MSCLKID });
  if (cookieValue) {
    const prefix = '_uet';
    const index = cookieValue.indexOf(prefix);
    if (index !== -1) {
      return cookieValue.substring(index + prefix.length);
    }
  }
  return null;
};

const getFBCLIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.FBP });
  return cookieValue?.split('.')?.[2] || null;
};
const getTTCLIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.TTCLID });
  return cookieValue?.split('.')?.[2] || null;
};
const getSCLIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.SCLID });
  return cookieValue?.split('.')?.[2] || null;
};

const getLIIDFromCookie = () => {
  const cookieValue = getCookie({ name: COOKIE_MAP.LIID });
  return cookieValue?.split('.')?.[2] || null;
};

export const getInitiateWhatsappChatLink = async () => {
  const showFullURL = false;
  const { pathname, searchParams } = new URL(window.location.href);
  const pageURLWithoutQueryParam = `${HOST_PORTAL_URL}${pathname}`;

  const searchString = searchParams.toString();
  const encodedQueryString =
    searchString.length > 0 ? `, ${encodeURIComponent(searchString)}` : '';

  const messagePrefix = config.isProduction
    ? ''
    : `Please Ignore this - this message is from the non production portal. \n\n`;

  const whatsAppBaseURL = 'https://api.whatsapp.com/send';

  const fullURL = showFullURL
    ? `\n\n${pageURLWithoutQueryParam}${encodedQueryString}`
    : '';

  const userAuth = getUserAuth();

  const leadMarketingReferencePayload = {
    hostId: userAuth?.isGuest ? null : userAuth?.id,
    guestUserId: userAuth?.isGuest ? userAuth.id : null,
    gclid: searchParams.get('gclid') ?? getGCLIDFromCookie(),
    fbclid: searchParams.get('fbclid') ?? getFBCLIDFromCookie(),
    msclkid: searchParams.get('msclkid') ?? getMSCLKIDFromCookie(),
    ttclid: searchParams.get('ttclid') ?? getTTCLIDFromCookie(),
    scclid: searchParams.get('scclid') ?? getSCLIDFromCookie(),
    liid: searchParams.get('li_fat_id') ?? getLIIDFromCookie(),
    utmSource: searchParams.get('utm_source') || null,
    utmMedium: searchParams.get('utm_medium') || null,
    utmCampaign: searchParams.get('utm_campaign') || null,
    utmTerm: searchParams.get('utm_term') || null,
    utmContent: searchParams.get('utm_content') || null,
    ytclid: searchParams.get('ytclid') || null,
    twclid: searchParams.get('twclid') || null
  };

  const hasValidData = _.some(
    leadMarketingReferencePayload,
    (value) => !_.isNil(value) && value !== ''
  );
  let referenceNumber = null;
  if (hasValidData) {
    try {
      const response = await createLeadMarketingReference(
        leadMarketingReferencePayload
      );
      if (!response.status) {
        console.error('Failed to create Lead Marketing Reference', response);
      }
      referenceNumber = response?.entity?.leadMarketingReferenceNumber;
    } catch (error) {
      console.error('Error while creating Lead Marketing Reference', error);
    }
  }

  const marketingLeadSuffix = referenceNumber
    ? `\n\nReferenceCode: ${referenceNumber}\n`
    : '';
  const formattedMessage = `${messagePrefix}Hi Hafla! I have a query on - ${pageURLWithoutQueryParam}.${fullURL}${marketingLeadSuffix}`;

  return encodeURI(
    `${whatsAppBaseURL}?phone=${hebaWhatsapp}&text=${formattedMessage}`
  );
};
